import React from 'react'
import Footer from '@partials/Footer'
import Header from '@partials/Header'

import tw from 'twin.macro'

import type { CustomMenu } from '@components/NavMenu/NavMenu'

type LayoutProps = {
  headerCover: any
  customMenu?: CustomMenu[]
  customFooter?: boolean
  children: React.ReactNode
}

const Layout = (props: LayoutProps) => {
  const { headerCover, children, customMenu, customFooter } = props

  return (
    <div tw="bg-[#FEF2D8] overflow-x-hidden">
      <Header coverComponent={headerCover} customMenu={customMenu} />
      <main>{children}</main>
      <Footer alternative={customFooter ?? false} />
    </div>
  )
}

export default Layout
